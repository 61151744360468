function scrollToo ($e, duration, y) {
    y = y || 0;
    duration = duration || 300;
    var _top = $e.offset().top + y;
    $('html,body').animate({"scrollTop" : _top}, duration);
    return false;
}

function sliderExec () {
    if ($('#slider').length) {
        var options = {
            duration: 7000,
            directory: $('meta[name="slider-url"]').attr('content'),
            directoryLoader: $('meta[name="loader-url"]').attr('content')
        };

        sliderExec.slider = new Slider(images, {
            container: $('.slider .slider__box'),
            navigation: $('.slider .slider__navigation'),
        }, options);
    }
}

function placeholder () {
    var fields = $('.forma__inp .itext, .forma__inp textarea');

    fields.keyup(function () {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
        else $(this).parent().find('label').show();
    }).focusin(function () {
        $(this).parent().addClass('forma__inp_light');
    }).focusout(function () {
        $(this).parent().removeClass('forma__inp_light');
        // if ($(this).val() == '') $(this).parent().removeClass('forma__inp_focus');
    });

    // fields.focusin(function () {
    //     $(this).parent().addClass('forma__inp_focus forma__inp_light');
    // }).focusout(function () {
    //     $(this).parent().removeClass('forma__inp_light');
    //     if ($(this).val() == '') $(this).parent().removeClass('forma__inp_focus');
    // });
}

/*********************************
********* + Menu mobile **********
*********************************/

function menuToggle () {
    var body = $('body');
    var menu = $('#mm');
    var toggleBut = $('.menu-toggle');
    var plusBut = $('#mm .plus');

    toggleBut.click(function () {
        if (menu.css('display') == 'block') {
            //body.removeClass('option-open-topbar');
            menu.slideUp(300, 'linear');
            toggleBut.removeClass('active');
        }
        else {
            menu.slideDown(300, 'linear');
            toggleBut.addClass('active');
            // body.addClass('option-open-topbar');
        }
    });

    plusBut.click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).next('.subs').slideUp(300, 'linear');
        } else if (!$(this).next().is('.subs:animated')) {
            $(this).addClass('active');
            $(this).next('.subs').slideDown(300, 'linear');
        }
    })

    $(window).resize(function() {
        setTimeout(function() {
            if (window.innerWidth >= 769) {
                menu.show();
                //body.removeClass('option-open-topbar');
                menu.find('.subs').each(function () { $(this).show(); })
            } else {
                if (!toggleBut.hasClass('active')) menu.hide();
                //else body.addClass('option-open-topbar');
                plusBut.each(function () {
                    if (!$(this).hasClass('active')) $(this).next('.subs').hide();
                })
            }
        }, 50);
    });
}

/********************************
******** END Menu mobile ********
********************************/

/********************************
******** Tags of projects *******
********************************/

var tags = function () {
    tags.$links = $('.tags');
    tags.$items = $('.projects .projects__item');

    tags.$links.find('a').click(function() {
        if (!$(this).hasClass('sel')) {
            var href = $(this).attr('href').substring(1);
            tags.$links.find('.sel').removeClass('sel');
            $(this).addClass('sel');

            tags.$items.each(function () {
                if ($(this).data('type') == href || href == 0) $(this).show();
                else $(this).hide();
            });
        }

        return false;
    });
}

/********************************
***** END Tags of projects ******
********************************/

/********************************
********** Form Valid ***********
********************************/

var CheckForms = function (form, button, options) {
    this.form = form;
    this.button = button;

    this.options = $.extend({}, CheckForms.Defaults, options);

    if (this.options.agreement != 1) {
        this.form.data('agreement', 0);
        this.agreementChange();
    } else this.form.data('agreement', 1);

    if (this.options.radio) this.radioChange();

    this.checkEmpty();
    this.submitForm();
    this.removeError();
}

CheckForms.Defaults = {
    agreement: 1,
    radio: 0,
}

CheckForms.prototype.checkEmpty = function () {
    this.form.find('.requiredField').each(function () {
        if ($(this).val() == '' || $(this).val() == $(this).parent().find('label').text()) $(this).addClass('emptyField');
    });

    this.checkAllElements();
}

CheckForms.prototype.checkCorrect = function () {
    // /^[0-9a-z-\.]+\@[0-9a-z-]{2,}\.[a-z]{2,}$/i) - email
    var allowedCharEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;

    this.form.find('.requiredField').each(function () {
        if ($(this).attr('name') == 'email' && !allowedCharEmail.test($(this).val())) $(this).addClass('errorField');
        if (!$(this).val()) $(this).addClass('errorField');
    });

    this.checkAllElements();
}

CheckForms.prototype.removeError = function () {
    var allowedCharEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;

    this.form.on('keyup blur', '.requiredField', function () {
        if ($(this).val() && $(this).val() != $(this).parent().find('label').text()) {
            if ($(this).attr('name') == 'email' && allowedCharEmail.test($(this).val())) $(this).removeClass('errorField emptyField');
            else if ($(this).attr('name') != 'email') $(this).removeClass('errorField emptyField');
        } else $(this).addClass('emptyField');

        if (!$(this).parents('form').find('.emptyField').length && !$(this).parents('form').find('.errorField').length && $(this).parents('form').data('agreement')) $(this).parents('form').find('.button_submit').removeClass('button_disabled');
        else $(this).parents('form').find('.button_submit').addClass('button_disabled');
    });
}

CheckForms.prototype.radioChange = function () {
    this.options.radio.change(function () {
        this.checkEmpty();
        if (this.form.find('.errorField').length) this.checkCorrect();
    }.bind(this));
}

CheckForms.prototype.agreementChange = function () {
    this.options.agreement.change(function () {
        this.options.agreement.is('input:checked') ? this.form.data('agreement', 1) : this.form.data('agreement', 0);
        this.checkAllElements();
    }.bind(this));
}

CheckForms.prototype.checkAllElements = function () {
    if (this.form.data('agreement') && !this.form.find('.emptyField').length && !this.form.find('.errorField').length) this.button.removeClass('button_disabled');
    else this.button.addClass('button_disabled');
}

CheckForms.prototype.submitForm = function () {
    if (!this.button.is('a')) {
        this.form.submit(function () {
            this.checkEmpty();
            this.checkCorrect();
            if (this.form.find('.emptyField').length || this.form.find('.errorField').length || !this.form.data('agreement')) event.preventDefault();
        }.bind(this));
    } else {
        this.button.click(function () {
            this.checkEmpty();
            this.checkCorrect();
            if (this.form.find('.emptyField').length || this.form.find('.errorField').length || !this.form.data('agreement')) event.preventDefault();
        }.bind(this));
    }
}

function execContactsForm() {
    var options = {
        agreement: $('[name = "agreement"]'),
    };
    var contactsForm = new CheckForms($('#feedForm'), $('#feedForm .button_submit'), options);
}

/********************************
******** END Form Valid *********
********************************/


function mapContacts() {
    ymaps.ready(init);
    var myMap,
    myPlacemark;
}

function init(){
    myMap = new ymaps.Map("map", {
        center: [46.968951, 142.707166],
        zoom: 16
    });

    myMap.behaviors.disable('scrollZoom');

    myPlacemark = new ymaps.Placemark([46.968951, 142.707166], {
        hintContent: 'ООО «Сахалинская механизированная колонна №68»',
        balloonContent: 'ООО «Сахалинская механизированная колонна №68»'
    }, {
        preset: 'islands#dotIcon',
        iconColor: '#29166f'
    });

    myMap.geoObjects.add(myPlacemark);
}


function prodGalleryExec() {
    $('.popup-gallery').magnificPopup({
		delegate: '.projects__item__inside',
		type: 'image',
		tLoading: 'Загрузка изображения #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>',
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
			titleSrc: function(item) {
				return item.el.attr('title');
			}
		}
	});
}

function initMap() {
    ymaps.ready(function () {
        var myMap = new ymaps.Map('map', {
            center: [places[0][0], places[0][1]],
            zoom: 16
        }, {
            searchControlProvider: 'yandex#search'
        });

        myMap.behaviors.disable(['scrollZoom', 'rightMouseButtonMagnifier', 'multiTouch']);

    	for (var key in places) {

    		placesExec = new ymaps.Placemark(
    		    places[key],
    		    {
    		        balloonContentHeader: '<div class="mcapt">' + places[key][2] + '</div>',
    		        balloonContentBody: '<div class="mimage">' + places[key][3] + '</div>',
    		        balloonContentFooter: '<div class="mbm">' + places[key][4] + '</div>'

    		    },
    		    {
                    preset: 'islands#dotIcon',
                    iconColor: '#007373'
    		    }
    		);
    		myMap.geoObjects.add(placesExec);
    	}
    });
}


x.exe['option-default'] = ['menuToggle()', 'placeholder()', 'execContactsForm()'];
x.exe['option-index'] = ['sliderExec()', 'tags()', 'prodGalleryExec()'];
x.exe['option-projects'] = ['tags()', 'prodGalleryExec()'];
x.exe['option-contacts'] = [/*'initMap()',*/ 'execContactsForm()'];
